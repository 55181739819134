.quote-main{
    background-color: #F4F4F4;
    height: 100vh;
}

.to-do-list {
    width: 92%;
    padding-top: 10px;
    padding-bottom: 20px;
}

.quote-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
}