.nav-wrapper {
    margin: auto;
    max-width: 90%;
    padding: 10px;
}

/* header */  
.header {
    background-color:#1DCD8D ;
    /* box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1); */
    position: fixed;
    width: 100%;
    z-index: 3;
}
  
.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}
  
.header li a {
    display: block;
    text-decoration: none;
    color: white;
}
  
.header li a:hover,
.header .menu-btn:hover {
    border-bottom: 1px solid #FFDE59;
}
  
.header .logo {
    width: 240px;
    height: 40px;
    background-size: cover;
    /* background-color: red; */
    /* display: block; */
    float: left;
    margin: 10px;
    background-image: url('../../assets/app_logo.png');

}
  
/* menu */
.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}
  
/* menu icon */  
.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}
  
.header .menu-icon .navicon {
    background: white;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}
  
.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
  
.header .menu-icon .navicon:before {
    top: 5px;
}
  
.header .menu-icon .navicon:after {
    top: -5px;
}
  
/* menu btn */  
.header .menu-btn {
    display: none;
}
  
.header .menu-btn:checked ~ .menu {
    max-height: 240px;
}
  
.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}
  
.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}
  
.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}
  
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */
@media (min-width: 48em) {
    .header li {
      float: left;
    }

    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }

    .header .menu-icon {
      display: none;
    }

    .header .login-item {
        padding: 10px;
    }
    .header .login-item .login-item-link {
        border: 1px solid white;
        border-radius: 20px;
        padding: 10px 40px;
    }

    .header .menu-item {
        padding: 20px 30px;
    }
}