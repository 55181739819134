.lan-logo {
  width: 10rem;
  margin-left: -15px;
}

.lan-image {
  width: 350px;
  height: 550px;
  margin-top: 20px;
}

.lan-right {
  padding: 40px;
}

.lan-left {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: left;
  margin-left: 90px;
  justify-content: center;
  margin-top: 10px;
}

.lan-title {
  font-size: 70px;
  font-weight: 600;
  max-width: 100vh;
}

.lan-sub-title {
  color: #fff;
  font-size: 50px;
  height: 50px;
  margin-top: -10px;
}

.howItWorks {
  color: #fff;
  font-size: 24px;
}

.lan-button{
  display: flex;
  flex-direction: row;
}

.lan-login-button{
  width: 170px;
  height: 50px;
  padding: 40px 0px 0px 10px;
}

.lan-dots-view{
  display: flex;
  flex-direction: row;
  justify-content:center;
  margin-top: 50px;
}

.lan-dots{
  width: 45px;
  height: 15px;
}

.try-now {
  font-size: 18px;
  border-radius: 20px;
  padding: 5px 30px;
  text-align: center;
  width: 130px;
  color: white;
  background-color:#F94D85;
  font-weight: 500;
}

.try-now {
  font-size: 18px;
  border-radius: 20px;
  padding: 5px 30px;
  text-align: center;
  width: 130px;
  color: white;
  background-color:#F94D85;
  font-weight: 500;
}

.chat {
  font-size: 18px;
  border: 1px solid white;
  border-radius: 20px;
  padding: 5px 30px;
  width: 130px;
  text-align: center;
  color: white;
  margin-left: 10px;
  font-weight: 500;
}