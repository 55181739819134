.header-nav-wrapper {
  padding: 10px;
  height: 70px;
  max-width: 95%;
  margin: auto;
}

.header-title{
  color: black;
  text-decoration: none;
  float: left;
  margin: 10px;
  font-size: 25px;
  font-weight: 600;
}

/* header-main */  
.header-main {
  background-color:white ;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header-main ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header-main li a {
  display: block;
  text-decoration: none;
  color: white;
}

.header-main li a:hover,
.header-main .header-menu-btn:hover {
  border-bottom: 1px solid #FFDE59;
}

.header-main .logo {
  width: 240px;
  height: 40px;
  background-size: cover;
  /* background-color: red; */
  /* display: block; */
  float: left;
  margin: 10px;
  background-image: url('../../assets/app_logo.png');

}

.header-main li {
  float: left;
}

.header-main .header-menu {
  clear: none;
  float: right;
  max-height: none;
}

.header-main .header-menu-icon {
  display: none;
}

.header-main .header-chat {
  padding: 10px;
}

.header-main .header-profile {
  padding: 10px;
}

.header-main .header-chat .header-link {
  /* border: 1px solid white; */
  background-color: #F94D85;
  border-radius: 5px;
  padding: 5px 70px;
}

.header-main .header-profile .header-link {
  border: 1px solid #1DCD8D;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  width: 35px;
  color: #1DCD8D;
}

.header-search-container{
  background-color: #E8E2E2;
  width: 100%;
}

.header-search{
  margin: auto;
  max-width: 95%;
  padding: 15px 10px;
}