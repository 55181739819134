.header-menu-main{
    background-color: #F4F4F4;
    // height: 100vh;
    // width: 100%;
}

.header-menu-container{
    // width: 100%;
    // height: 130px;
    background-color: #E8E2E2;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding-top: 70px;
    padding-left: 50px;
    width: 100%;
}

.header-menu-bar{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    // background-color: aqua;
    background-color: #E8E2E2;
}

.header-menu-next{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
    border: 1px solid grey;

}
