.auth-image {
  width: 400px;
  height: auto;
}

.auth-gif-icon {
  width: 100px;
  height: auto;
}

.auth-left{
  padding: 100px;
  align-items: center;
}

.auth-right {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  vertical-align: middle;
  align-self: flex;
}

.auth-sub-title {
  color: black;
  font-size: 30px;
  height: 50px;
  text-align: left;
}

.auth-regular-text {
  font-size: 14px;
  text-align: right;
  color: #1DCD8D;
}

.auth-button-text {
  font-size: 18px;
  border-radius: 20px;
  padding: 5px 30px;
  text-align: center;
  width: 130px;
  color: white;
  background-color:#1DCD8D;
  font-weight: 500;
  margin-top: 50px;
}

.auth-portal-container {
  display: flex;
  width: 400px;
}

.side-content-center {
  display: flex;
  align-items: center;
  margin: 5px 10px;
  font-size: 16px;
}

.auth-button{
  display: flex;
  justify-content: center;
}
