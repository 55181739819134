.quote-detail-row-container{
    display: flex;
    // align-items: center;
    // justify-content: center;
    margin-left:30px;
    margin-top: 10px;
}

.quote-detail-confirm-button{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100px;
    height: 30px;
    }

.quote-detail-cancel-button{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100px;
    height: 30px;
    margin-left: 10px;
}

.header-menu-main{
    background-color: #F4F4F4;
}

.quote-detail-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.quote-detail-content{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.quote-detail-back{
    border: 1.5px solid #1DCD8D;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    color: #1DCD8D;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: 400px;
}

.quote-detail-reference-back{
    border: 1.5px solid #1DCD8D;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    color: #1DCD8D;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quote-detail-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.quote-detail-photos{
    margin-top: 20px;
    margin-bottom: 20px;
}

.quote-detail-reference{
    display: flex;
    // justify-content: flex-start;
    // flex-direction: row;
    // align-items: flex-start;
    // width: 50%;
}