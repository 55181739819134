	/* @font-face {
	font-family: "AlegreyaSC-Bold";
	src: local("AlegreyaSC-Bold"),
	 url("./assets/fonts/AlegreyaSC-Bold.ttf") format("truetype");
	}
	
	@font-face {
	font-family: "OpenSans-Regular";
	src: local("OpenSans-Regular"),
	 url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
	} */
	
	body {
	  margin: 0;
	  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 
		sans-serif;
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	  
	}
	
	
	body::-webkit-scrollbar-track {
	  background: #ececec;
	}
	
	body::-webkit-scrollbar {
	  width: 12px;
	}
	
	body::-webkit-scrollbar-thumb {
	  background-color: #333333a2;
	  border-radius: 0px;
	  border-top: 5px solid #ececec;
	  border-bottom: 5px solid #ececec;
	  border-left: 5px solid #ececec;
	  border-right: 5px solid #ececec;
	}
	
	body::-webkit-scrollbar-thumb:hover {
	  background-color: #c9c6c6;
	  border-top: 0px ;
	  border-bottom: 0px ;
	  border-left: 2px solid #ececec;
	  border-right: 2px solid #ececec;
	}
	
	
	
	code {
	  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Open Sans',
		monospace;
	}
	