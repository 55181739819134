.table-delete-container{
    // background-color: red;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 80px;
    height: 30px;
    margin-left: 10px;
}

.table-cancel-container{
    // background-color: #F94D85;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 80px;
    height: 30px;

}


.table-delete-container-main{
    display: flex;
    // align-items: center;
    justify-content: center;
    margin-top: -3px;

}

.table-status-container{
    display: flex;

}
